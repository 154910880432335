<template>
  <div class="map-wrapper">
    <div id="map"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import L from 'leaflet'
import 'leaflet.gridlayer.googlemutant'
import { isEmpty, find } from 'lodash'
import 'leaflet-kml'
import '@gnatih/leaflet.legend'
import '@inelo/leaflet-control-geocoder'

import 'leaflet/dist/leaflet.css'
import '@gnatih/leaflet.legend/src/leaflet.legend.css'
import '@inelo/leaflet-control-geocoder/dist/Control.Geocoder.css'
import api from '../api'

export default {
  props: ['selectedWard'],
  data() {
    return {
      center: [23.0225, 72.5714],
      zoom: 12,
      map: null,
      ward: null,
      wards_layer: null,
      legend: null,
      base_legend: [
        {
          label: 'Low Risk',
          type: 'circle',
          radius: 12,
          color: 'white',
          fillColor: '#00796b',
          weight: 2,
        },
        {
          label: 'Medium Risk',
          type: 'circle',
          radius: 12,
          color: 'white',
          fillColor: '#ffb300',
          weight: 2,
        },
        {
          label: 'High Risk',
          type: 'circle',
          radius: 12,
          color: 'white',
          fillColor: '#d32f2f',
          weight: 2,
        },
      ],
    }
  },
  mounted() {
    this.setupLeaflet()
  },
  watch: {
    selectedWard(val) {
      if (val == 'all') {
        this.ward = null
        this.updateWards()
      } else {
        this.ward = find(this.wards, { id: val })
        this.updateWards()
      }
    },
  },
  computed: {
    ...mapGetters(['wards', 'r_x00', 'analysis_layers']),
  },
  methods: {
    ...mapActions(['fetchWards', 'setRx00']),

    setupLeaflet() {
      this.fetchWards().then(() => {
        this.map = L.map('map', {
          center: this.center,
          zoom: this.zoom,
        })

        this.legend = L.control
          .Legend({
            position: 'bottomright',
            legends: this.base_legend,
          })
          .addTo(this.map)

        const geocoder = L.Control.Geocoder.google({
          apiKey: process.env.VUE_APP_API_KEY,
        })

        L.Control.geocoder({ geocoder: geocoder, placeholder: 'Search location', position: 'topleft' }).addTo(this.map)
        L.control.scale().addTo(this.map)

        const baseLayers = {
          Satellite: L.gridLayer.googleMutant({
            type: 'satellite',
          }),
          Roadmap: L.gridLayer.googleMutant({
            type: 'roadmap',
          }),
        }

        this.map.addLayer(baseLayers.Satellite)
        L.control.layers(baseLayers, [], { position: 'topleft' }).addTo(this.map)

        this.kml_layers = new L.LayerGroup([], { interactive: false }).addTo(this.map)

        api.loadGeoJSON('AMC_Ward_Boundary').then((res) => {
          this.wards_layer = L.geoJSON(res, {
            style: (feature) => {
              return {
                weight: 1,
                color: 'white',
              }
            },
            onEachFeature: (feature, layer) => {
              layer
                .bindTooltip(feature.properties.Name, {
                  permanent: true,
                  className: 'ward-label',
                  direction: 'center',
                })
                .closeTooltip()
            },
          }).addTo(this.map)

          this.$nextTick(() => {
            this.updateWards()
          })
        })
      })
    },

    updateWards() {
      this.$store.state.fewdss.loading = true
      this.map.closePopup()
      this.kml_layers.clearLayers()
      this.legend.remove()

      let legend = JSON.parse(JSON.stringify(this.base_legend))
      let ward_selected = !isEmpty(this.ward)

      this.wards_layer.eachLayer((layer) => {
        let ward = find(this.wards, { id: parseInt(layer.feature.properties.New_Ward_N) })

        if (ward) {
          let risk = ward['r_' + this.r_x00]

          let color = risk == 'High' ? 'var(--high-color)' : risk == 'Medium' ? 'var(--medium-color)' : 'var(--low-color)'
          let layerStyles = {
            fillColor: this.ward && this.ward.id !== parseInt(layer.feature.properties.New_Ward_N) ? 'white' : color,
            fillOpacity: (this.ward && this.ward.id == parseInt(layer.feature.properties.New_Ward_N)) || !this.ward ? 0.7 : 0.3,
          }

          layer.setStyle(layerStyles)
          layer.bindPopup(api.getPopupContent(ward, this.r_x00), { ward: ward, className: risk.toLowerCase() })

          if (ward_selected && parseInt(layer.feature.properties.New_Ward_N) == this.ward.id) {
            this.map.fitBounds(layer.getBounds())
          }

          if (!ward_selected) {
            this.map.flyTo(this.center, this.zoom)
          }
        }
      })

      this.analysis_layers.forEach((layer) => {
        fetch(`/kml/${layer.file}.kml`)
          .then((res) => res.text())
          .then((kmltxt) => {
            const parser = new DOMParser()
            const kml = parser.parseFromString(kmltxt, 'text/xml')
            const track = new L.KML(kml)

            this.kml_layers.addLayer(track)
            legend.push(layer)
          })
      })

      this.$store.state.fewdss.loading = false
      this.legend = L.control
        .Legend({
          position: 'bottomright',
          legends: [...this.base_legend, ...this.analysis_layers],
        })
        .addTo(this.map)
      // this.legend.addTo(this.map)
      // this.legend.redraw()
    },
  },
}
</script>

<style lang="scss">
:root {
  --low-color: #00796b;
  --medium-color: #ffb300;
  --high-color: #d32f2f;
}

#map,
.map-wrapper {
  width: 100%;
  height: 100%;
}

.leaflet-popup-close-button span {
  color: rgba(255, 255, 255, 0.5);
}

.leaflet-popup-content {
  min-width: 240px !important;
}

.leaflet-pane {
  z-index: 180;
}

.leaflet-control {
  z-index: 190;
}

.leaflet-popup {
  --header-height: 80px;
  --background-color: white;

  &.low {
    --background-color: var(--low-color);
  }

  &.medium {
    --background-color: var(--medium-color);
  }

  &.high {
    --background-color: var(--high-color);
  }

  & .leaflet-popup-content-wrapper {
    background: linear-gradient(180deg, var(--background-color) 0%, var(--background-color) var(--header-height), white var(--header-height), white 100%);
  }
}

.ward-label {
  background: transparent;
  border: none;
  color: white;
  box-shadow: none;
  font-size: 9px;
}

.grid {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.leaflet-legend-item {
  border-spacing: 4px 0;
  i {
    padding: 0;
  }
}
</style>
