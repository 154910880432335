export default {
  async loadGeoJSON(name) {
    const response = await fetch(`/geojson/${name}.geojson`)
    const data = await response.json()

    return data
  },

  getPopupContent(ward, rx00) {
    return `
    <div>
    <h2 class="white--text">
      <span class="caption">${ward.zone.toUpperCase()} ZONE / Ward ${ward.id}</span><br />${ward.name}
    </h2>

    <div class="grid">
      <div class="grid-col">
        <div class="caption text--secondary text-uppercase">Rainfall Risk</div>
        <h3>${ward['r_' + rx00] }</h3>
      </div>
      <div class="grid-col">
        <div class="caption text--secondary text-uppercase">Inundation</div>
        <h3>${ward['i_' + rx00] } in</h3>
      </div>
      <div class="grid-col">
        <div class="caption text--secondary text-uppercase">Area</div>
        <h3>${ward.area} sq km</h3>
      </div>
      <div class="grid-col">
        <div class="caption text--secondary text-uppercase">Predicted Rainfall</div>
        <h3>${ward.rainfall} mm</h3>
      </div>
    </div>

    <div class="solution mt-5">
      <div class="caption text--secondary text-uppercase">Solutions</div>
      <h3>${ward[ward['r_' + rx00].toLowerCase()]}</h3>
    </div>

    <div class="caption mt-5 text--secondary">Note: Rainfall Risk, Inundation and Solution are calculated for ${rx00 > 5 ? '5' : rx00 > 2 ? '2' : '0' } - ${rx00} inches of rainfall.</div>
  </div>
    `;
  },
}
