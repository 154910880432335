import { indexOf, meanBy, filter } from 'lodash'

const state = {
  wards: [],
  rainfall: 0,
  loading: false,
  analysis_layers: [
    {
      id: 1,
      file: 'AMC_Zones',
      label: 'AMC Zones',
      visible: false,
      type: 'rectangle',
      color: '#ffff00',
      fillColor: '#efefef',
      weight: 2,
      fillOpacity: 0.6,
    },
    {
      id: 2,
      file: 'Water_Logging_Spots',
      label: 'Water Logging Spots',
      visible: false,
      type: 'image',
      url: 'https://maps.google.com/mapfiles/kml/shapes/water.png'
    },
    {
      id: 3,
      file: 'Kharicut_Canal',
      label: 'Kharicut Canal',
      type: 'polyline',
      fillColor: '#ff0000',
      color: '#55aaff',
      weight: 3,
      visible: false,
    },
    {
      id: 4,
      file: 'Sewage_Manholes',
      label: 'Sewage Manholes',
      visible: false,
      type: 'image',
      url: 'https://maps.google.com/mapfiles/kml/shapes/target.png'      
    },
    {
      id: 5,
      file: 'Sewage_Pumping_Station',
      label: 'Sewage Pumping Station',
      url: 'https://maps.google.com/mapfiles/kml/shapes/square.png',
      type: 'image',      
      visible: false,
    },
    {
      id: 6,
      file: 'Sewage_Treatment_Plant',
      label: 'Sewage Treatment Plant',
      type: 'image',
      url: 'https://maps.google.com/mapfiles/kml/shapes/triangle.png',
      visible: false,
    },
    {
      id: 7,
      file: 'Sewer_Network',
      label: 'Sewer Network',
      type: 'polyline',
      color: '#5f0f40',
      weight: 3,
      visible: false,
    },
    {
      id: 8,
      file: 'Stormwater_Network',
      label: 'Stormwater Network',
      type: 'polyline',
      weight: 3,
      color: '#55aaff',
      visible: false,
    },
    {
      id: 9,
      file: 'Stormwater_Pump',
      label: 'Stormwater Pump',
      url: 'https://maps.google.com/mapfiles/kml/shapes/open-diamond.png',
      type: 'image',
      visible: false,
    },
    {
      id: 10,
      file: 'Stormwater_Pumping_Station',
      label: 'Stormwater Pumping Station',
      url: 'https://maps.google.com/mapfiles/kml/shapes/donut.png',
      type: 'image',
      visible: false,
    },
    {
      id: 11,
      file: 'Water_Bodies',
      label: 'Water Bodies',
      type: 'rectangle',
      color: '#0055ff',
      weight: 2,
      fillColor: '#0055ff',
      fillOpacity: 0.4,
      visible: false,
    },
  ],
}

const getters = {
  wards: (state) => state.wards,
  averageRainfall: (state) => meanBy(state.wards, (ward) => ward.rainfall),
  r_x00: state => Math.ceil(state.rainfall) > 5 ? 10 : Math.ceil(state.rainfall > 2) ? 5 : 2,
  analysis_layers: (state) => filter(state.analysis_layers, { visible: true }),
}

const actions = {
  async fetchWards({ commit, getters }) {
    let cached = localStorage.getItem('fewdss-storage')

    if (cached) {
      state.wards = JSON.parse(cached)
    } else {
      const sheetId = '1UxQI9CnbqRh3P3Ta5PfB9B6R6kjuLxBl6cgi1aGJnPY'
      const service = 'https://opensheet.elk.sh'

      cached = await fetch(`${service}/${sheetId}/Sheet1`).then((r) => r.json())
    }

    cached.forEach((item) => {
      item.id = parseInt(item.id)
      item.lat = parseFloat(item.lat)
      item.lon = parseFloat(item.lon)
      item.area = parseFloat(item.area)
      item.i_100 = parseFloat(item.i_100)
      item.i_200 = parseFloat(item.i_200)
      item.i_300 = parseFloat(item.i_300)
      item.i_400 = parseFloat(item.i_400)
      item.rainfall = parseFloat(item.rainfall)
    })

    commit('saveWards', cached)
    commit('saveRainfall', getters.averageRainfall * 0.0394)

    return new Promise((callback) => {
      callback()
    })
  },
  setRainfall: ({ commit }, rainfall) => {
    commit('saveRainfall', Math.ceil(rainfall * 0.0394))
  },
}

const mutations = {
  saveWards: (state, wards) => (state.wards = wards),
  saveRainfall: (state, rainfall) => {
    if(rainfall < 0.01) rainfall = 0;
    state.rainfall = rainfall
  },
  saveAnalysisLayers: (state, layers) => {
    state.analysis_layers.forEach((layer, index) => {
      state.analysis_layers[index].visible = indexOf(layers, layer.id) !== -1 ? true : false
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
