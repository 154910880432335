<template>
  <v-app>
    <v-overlay :value="$store.state.fewdss.loading">
      <v-progress-circular indeterminate size="128">Loading..</v-progress-circular>
    </v-overlay>
    <v-btn id="show-drawer-btn" absolute icon @click="show_drawer = !show_drawer" v-show="$vuetify.breakpoint.mobile">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer app right v-model="show_drawer" :permanent="$vuetify.breakpoint.mdAndUp || show_drawer" v-bind:width="320">
      <v-list-item dark class="grey darken-3">
        <v-list-item-content>
          <v-list-item-title class="text-h6">F E W D S S</v-list-item-title>
          <v-list-item-subtitle>
            Flood Early Warning &<br />
            Decision Support System
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content class="text-caption">Average Predicted Rainfall on {{ new Date().toLocaleDateString('en-IN', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} is {{ (averageRainfall * 0.0394).toFixed(2) }} in. </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-slider :min="0" :max="10" ticks="always" hint="Rainfall (in.)" class="my-3" persistent-hint thumb-label="always" :thumb-size="18" :tick-labels="tickLabels" step="1" v-model="rainfall"></v-slider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-select v-model="ward" label="Ward" :items="allWardsSelect" item-text="name" item-value="id" outlined></v-select>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-select v-model="analysis_layers" label="Analysis Layers" :items="$store.state.fewdss.analysis_layers" item-text="label" item-value="id" outlined multiple>
            <template v-slot:prepend-item v-if="$store.state.fewdss.analysis_layers.length > 1">
              <v-list-item @mousedown.prevent @click="toggle">
                <v-list-item-action>
                  <v-icon color="indigo darken-4">{{ selectAllIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> {{ selectAllText }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-list-item-content>
      </v-list-item>

      <template v-slot:append>
        <v-list-item>
          <v-list-item-content>
            <div class="text-center text-caption mb-3">In collaboration with</div>
            <div class="text-center mb-3 d-flex justify-center">
              <a href="https://ahmedabadcity.gov.in/" target="_blank">
                <v-img src="/images/amc-logo.png" width="100"></v-img>
              </a>
              <a href="https://accionland.com/" style="align-self: center" target="_blank"><v-img src="/images/accionland-logo.png" width="90"></v-img></a>
            </div>
            <div class="text--secondary text-caption text-center" @click.prevent="dialog = true">Copyright &copy; 2022 | <a href="javascript:;">About this tool</a></div>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid fill-height pa-0>
        <leaflet-map ref="map" :selected-ward="ward"></leaflet-map>
      </v-container>
    </v-main>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title> About this tool </v-card-title>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { filter, sortBy, map, groupBy } from 'lodash'
import { mapGetters } from 'vuex'
import LeafletMap from './components/LeafletMap'

export default {
  components: { LeafletMap },
  data() {
    return {
      loading: true,
      ward: 'all',
      tickLabels: ['0', '', '2', '', '', '5', '', '', '', '', '10'],
      dialog: false,
      show_drawer: false,
    }
  },
  computed: {
    rainfall: {
      get() {
        return Math.ceil(this.$store.state.fewdss.rainfall * 0.0394)
      },
      set(val) {
        this.$store.commit('saveRainfall', val)

        this.$nextTick(() => {
          this.$refs.map.updateWards()
        })
      },
    },
    analysis_layers: {
      get() {
        return filter(this.$store.state.fewdss.analysis_layers, (layer) => layer.visible)
      },
      set(val) {
        this.$store.commit('saveAnalysisLayers', val)
        this.$refs.map.updateWards()
      },
    },
    allWardsSelect() {
      let wards_json = groupBy(JSON.parse(JSON.stringify(sortBy(this.wards, 'name'))), 'zone')
      let wards = []

      for (let zone in wards_json) {
        wards.push({ header: `${zone} Zone`.toUpperCase() })
        wards_json[zone].forEach((ward) => wards.push(ward))
      }

      wards.unshift({ id: 'all', name: 'All Wards' })

      return wards
    },
    selectAllIcon() {
      return this.analysis_layers.length == 0 ? 'mdi-checkbox-blank-outline' : this.analysis_layers.length == this.$store.state.fewdss.analysis_layers.length ? 'mdi-close-box' : 'mdi-minus-box'
    },
    selectAllText() {
      return this.analysis_layers.length == 0 ? 'Select all' : 'Clear all'
    },
    ...mapGetters(['averageRainfall', 'wards']),
  },
  methods: {
    toggle() {
      if (this.analysis_layers.length == 0) {
        let layers = map(this.$store.state.fewdss.analysis_layers, 'id')
        this.$store.commit('saveAnalysisLayers', layers)
      } else {
        this.$store.commit('saveAnalysisLayers', [])
      }
      this.$refs.map.updateWards()
    },
  },
}
</script>

<style>
.v-overlay.v-overlay--active {
  z-index: 9999 !important;
}

#show-drawer-btn {
  z-index: 190;
  background: white;
  right: 10px;
  top: 10px;
}

.v-slider__tick-label {
  font-size: 9px;
}
</style>
